import { validateExpondoStandardProductSKU } from '~/helpers/validation/expondoSKUValidator';

type extractionResult = {skus: string[], warnings: string[]}

const extractBestsellerValidSKUs = (commaSeparatedSkus: string | null): extractionResult => {
  const result: extractionResult = { skus: [], warnings: [] };

  if (!commaSeparatedSkus) {
    result.warnings.push('Not set SKU list for CMS ' +
      'component. Best-sellers will not be displayed for this particular component');

    return result;
  }

  if (validateExpondoStandardProductSKU(commaSeparatedSkus)) {
    result.skus.push(`"${commaSeparatedSkus}"`); // it contains only one SKU, without comma

    return result;
  }

  commaSeparatedSkus.split(',').forEach((skuCandidate: string) => {
    const trimmedSku = skuCandidate.trim();

    if (validateExpondoStandardProductSKU(trimmedSku)) {
      result.skus.push(`"${trimmedSku}"`);
    } else {
      result.warnings.push(`Invalid SKU ${trimmedSku} provided in the list for best-sellers. It wil be skipped`);
    }
  });

  return result;
};

export default extractBestsellerValidSKUs;
